import Mock from "../mock";

const database = {
  navigation: {
    _home: "HOME",
    _about: "ABOUT",
    _resume: "RESUME",
    _portfolios: "PORTFOLIOS",
    _blogs: "BLOGS",
    _contact: "CONTACT"
  },
  information: {
    _aboutMe: "ABOUT ME",
    _welcome: 'Hi, I am',
    _iAm: 'I am',
    _fullname: "Full Name",
    _age: "Age",
    _phone: "Phone",
    _nationality: "Nationality",
    _languages: "Languages",
    _email: "Email",
    _freelance: "Freelance",    
    _downloadCv: "DOWNLOAD CV",
    name: 'Miroslav Adamec',
    aboutContent: "I am full-stack .NET developer. I focus on business process automation using modern (not only) cloud technologies.",
    aboutDetail: "I developed my first program 15 years ago in Pascal. While studying at university, I started working for an international travel agency. I saw opportunities to automate the work of employees and then I started to focus on their implementation. This form of reducing the cost of running a company has become my main job goal.",
    age: 31,
    phone: '+421 903 441 145',
    nationality: 'Slovak',
    language: 'English, Slovak',
    email: 'miroslav.adamec@hotmail.com',
    address: '',
    freelanceStatus: 'Available',
    socialLinks: {
      facebook: '',
      twitter: '',
      pinterest: '',
      behance: '',
      linkedin: 'https://www.linkedin.com/in/miroslav-adamec-672b13b7/',
      dribbble: '',
      github: 'https://github.com/mirecad'
    },
    brandImage: '/images/brand-image.jpg',
    aboutImage: '/images/about-image.jpg',
    aboutImageLg: '/images/about-image-lg.jpg',
    cvfile: '/files/Adamec_MiroslavEN.pdf'
  },
  services: [
    {
      title: "Process Automation",
      icon: 'investment',
      details: "Every repetitive process can be automated. Let your employees focus on profitable work."
    },
    {
      title: "Web Development",
      icon: 'world',
      details: "Manage your business by internet browser from anywhere around the world."
    },
    {
      title: "Mobile Applications",
      icon: 'mobile',
      details: "Everybody has smartphone. Put control of your systems into your pocket device."
    }
  ],
  reviews: [
    {
      id: 1,
      content: "Miroslav is very talented person with strong technical skills. Having him in team is definitely big win.",
      author: {
        name: 'Vilém Svoboda',
        designation: 'CIO, Invia group (CZ)'
      }
    },
    {
      id: 2,
      content: "Miroslav cooperates with us for almost 2 years. We value his independence and fast response time.",
      author: {
        name: 'Peter Harbulák',
        designation: 'Founder, GYN-FIV, a.s. (SK)'
      }
    },
    {
      id: 3,
      content: "Attention to details maked Miroslavs work different from all the other average programmers.",
      author: {
        name: 'Michal Hliničan',
        designation: 'Product Owner, George Labs (AT)'
      }
    }
  ],
  skills: [
    {
      title: "HTML5",
      value: 95
    },
    {
      title: "CSS3",
      value: 90
    },
    {
      title: "Javascript",
      value: 70
    },
    {
      title: "jQuery",
      value: 85
    },
    {
      title: "ReactJS",
      value: 80
    },
    {
      title: "Photoshop",
      value: 65
    }
  ],
  portfolios: [
    {
      id: 1,
      title: "T-shirt Mockup",
      subtitle: "A beautiful t-shirt mockup.",
      imageUrl: "/images/portfolio-image-1.jpg",
      largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
      url: 'https://dribbble.com'
    },
    {
      id: 2,
      title: "Coffee Mug",
      subtitle: "Awesome coffee mug design.",
      imageUrl: "/images/portfolio-image-4.jpg",
      largeImageUrl: [
        "/images/portfolio-image-4-lg.jpg",
        "/images/portfolio-image-4-lg2.jpg"
      ],
      url: 'https://facebook.com'
    },
    {
      id: 3,
      title: "Tea & Coffee Mug",
      subtitle: "Beautiful mug with logo.",
      imageUrl: "/images/portfolio-image-2.jpg",
      url: 'https://pinterest.com'
    },
    {
      id: 4,
      title: "Pen Holder",
      subtitle: "A pen holder with beautiful design.",
      imageUrl: "/images/portfolio-image-3.jpg",
      largeImageUrl: ["/images/portfolio-image-3-lg.jpg"],
      url: '#'
    },
    {
      id: 5,
      title: "Mug",
      subtitle: "Mug with awesome style",
      imageUrl: "/images/portfolio-image-5.jpg",
      largeImageUrl: ["/images/portfolio-image-5-lg.jpg"]
    },
    {
      id: 6,
      title: "Pendrive",
      subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/portfolio-image-6.jpg",
      largeImageUrl: ["/images/portfolio-image-6-lg.jpg"]
    },
    {
      id: 7,
      title: "Beautiful Pendrive",
      subtitle: "Pendrive with great design & flexible.",
      imageUrl: "/images/portfolio-image-7.jpg",
      largeImageUrl: ["/images/portfolio-image-7-lg.jpg"],
      url: 'https://twitter.com'
    },
    {
      id: 8,
      title: "Sticker",
      subtitle: "Clip sticker mockup design.",
      imageUrl: "/images/portfolio-image-8.jpg",
      largeImageUrl: ["/images/portfolio-image-8-lg.jpg"]
    },
    {
      id: 9,
      title: "Packet",
      subtitle: "Beautiful packet & product design.",
      imageUrl: "/images/portfolio-image-9.jpg",
      largeImageUrl: ["/images/portfolio-image-9-lg.jpg"]
    },
    {
      id: 10,
      title: "Pen Holder",
      subtitle: "A pen holder with beautiful design.",
      imageUrl: "/images/portfolio-image-3.jpg",
      largeImageUrl: ["/images/portfolio-image-3-lg.jpg"],
      url: '#'
    },
    {
      id: 11,
      title: "Coffee Mug",
      subtitle: "Awesome coffee mug design.",
      imageUrl: "/images/portfolio-image-4.jpg",
      largeImageUrl: [
        "/images/portfolio-image-4-lg.jpg",
        "/images/portfolio-image-4-lg2.jpg"
      ],
      url: 'https://facebook.com'
    },
    {
      id: 12,
      title: "Tea & Coffee Mug",
      subtitle: "Beautiful mug with logo.",
      imageUrl: "/images/portfolio-image-2.jpg",
      url: 'https://pinterest.com'
    },
    {
      id: 13,
      title: "T-shirt Mockup",
      subtitle: "A beautiful t-shirt mockup.",
      imageUrl: "/images/portfolio-image-1.jpg",
      largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
      url: 'https://dribbble.com'
    },
    {
      id: 14,
      title: "Mug",
      subtitle: "Mug with awesome style",
      imageUrl: "/images/portfolio-image-5.jpg",
      largeImageUrl: ["/images/portfolio-image-5-lg.jpg"]
    },
    {
      id: 15,
      title: "Pendrive",
      subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/portfolio-image-6.jpg",
      largeImageUrl: ["/images/portfolio-image-6-lg.jpg"]
    },
    {
      id: 16,
      title: "Beautiful Pendrive",
      subtitle: "Pendrive with great design & flexible.",
      imageUrl: "/images/portfolio-image-7.jpg",
      largeImageUrl: ["/images/portfolio-image-7-lg.jpg"],
      url: 'https://twitter.com'
    },
    {
      id: 17,
      title: "Sticker",
      subtitle: "Clip sticker mockup design.",
      imageUrl: "/images/portfolio-image-8.jpg",
      largeImageUrl: ["/images/portfolio-image-8-lg.jpg"]
    },
    {
      id: 18,
      title: "Packet",
      subtitle: "Beautiful packet & product design.",
      imageUrl: "/images/portfolio-image-9.jpg",
      largeImageUrl: ["/images/portfolio-image-9-lg.jpg"]
    },
    {
      id: 19,
      title: "T-shirt Mockup",
      subtitle: "A beautiful t-shirt mockup.",
      imageUrl: "/images/portfolio-image-1.jpg",
      largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
      url: 'https://dribbble.com'
    },
    {
      id: 20,
      title: "Coffee Mug",
      subtitle: "Awesome coffee mug design.",
      imageUrl: "/images/portfolio-image-4.jpg",
      largeImageUrl: [
        "/images/portfolio-image-4-lg.jpg",
        "/images/portfolio-image-4-lg2.jpg"
      ],
      url: 'https://facebook.com'
    },
    {
      id: 21,
      title: "Tea & Coffee Mug",
      subtitle: "Beautiful mug with logo.",
      imageUrl: "/images/portfolio-image-2.jpg",
      url: 'https://pinterest.com'
    },
    {
      id: 22,
      title: "Pen Holder",
      subtitle: "A pen holder with beautiful design.",
      imageUrl: "/images/portfolio-image-3.jpg",
      largeImageUrl: ["/images/portfolio-image-3-lg.jpg"],
      url: '#'
    },
    {
      id: 23,
      title: "Mug",
      subtitle: "Mug with awesome style",
      imageUrl: "/images/portfolio-image-5.jpg",
      largeImageUrl: ["/images/portfolio-image-5-lg.jpg"]
    }
  ],
  experience: {    
    _workingExperience: "Working Experience",
    workingExperience: [
      {
        id: 1,
        year: "JANUARY 2019 - Present",
        position: "FREELANCER",
        company: "",
        details: "I started building all kinds of projects for various customers. From automatic excel file generation, through extensions to existing PHP projects, to larger websites built from scratch."
      },
      {
        id: 2,
        year: "MARCH 2018 - Present",
        position: ".NET DEVELOPMENT",
        company: "Invia Bratislava",
        details: "I left my role as IT support team leader and became technical leader of small development unit (me + 1 developer + 1 operations technician). My primary focus lies now in business process automation, maintaining previous projects and migrating them to platform independent and cloud friendly form. As of 2021 we have migrated almost every application to .NET Core and Azure. This helped to increase stability and to reduce operations time. Most used technologies: Azure, ASP.NET Core, MS SQL, Docker"
      },
      {
        id: 3,
        year: "JUNE 2015 - MARCH 2018",
        position: "IT SUPPORT LEADER + .NET DEVELOPMENT",
        company: "Invia Bratislava",
        details: "Leading of IT support department (10 persons) + design, development and maintenance of .NET services and applications, administration of Windows infrastructure and VoIP PBX in international travel agency. My primary goal is to reduce human interaction in IT tasks and improve user experience in systems managed by this department. I achieve this by analyzing actual workflows and then designing and implementing all kinds of applications and systems using mostly .NET. I do all phases of development on my own (company size: 350 – 700 employees based on season)."
      },
      {
        id: 4,
        year: "JUNE 2014 - JUNE 1016",
        position: "DOMAIN ADMINISTRATOR + .NET DEVELOPMENT",
        company: "Invia Brno",
        details: "Administration of Windows infrastructure, VoIP PBX Administration in international travel agency. Design, development and maintenance of system, that help to automate account and user rights management. It connects CRM to AD, Exch, PBX and reduces manual IT interaction in this process."
      },
      {
        id: 5,
        year: "JUNE 2012 - JUNE 1014",
        position: "DOMAIN ADMINISTRATOR",
        company: "Invia Brno",
        details: "Administration of Windows infrastructure in international travel agency. Active directory, Exchange, Hyper-V, MS SQL, GPO, IIS, DHCP + Linux VoIP PBX. Company has 200 - 500 employees based on season."
      }
    ],
    educationExperience: [
      {
        id: 1,
        year: "JUNE 2017",
        graduation: "ENGINEER",
        university: "SLOVAK UNIVERSITY OF TECHNOLOGY, BRATISLAVA",
        details: "Faculty of Electrical Engineering and Information Technology, discipline Applied Informatics"
      },
      {
        id: 2,
        year: "JANUARY 2015",
        graduation: "BACHELOR",
        university: "MASARYK UNIVERSITY, BRNO",
        details: "Faculty of Informatics, discipline: Computer systems and data processing"
      }
    ]
  },
  _projectsTitle: "LARGEST PROJECTS",
  projects: [
    {
      id: 1,
      title: 'Realtime email archiving solution',
      details: 'Plugin for Microsoft Exchange that inspects emails in realtime and archives them.',
      icon: 'envelope',
      filesource: '../../projectMD/mxarchive.md'
    },
    {
      id: 2,
      title: 'Employee account synchronization',
      details: 'Service that connects CRM system to IT backend systems (mail server, AD, PBX).',
      icon: 'user',
      filesource: '../../projectMD/employee-synchronization.md'
    },
    {
      id: 3,
      title: 'Bussiness process automation',
      details: 'Projects, that automate manual work performed by humans on computers.',
      icon: 'cog',
      filesource: '../../projectMD/process-automation.md'
    },
    {
      id: 4,
      title: 'Medical clinic task management website',
      details: 'Websites for storing communication and managing work tasks.',
      icon: 'flag',
      filesource: '../../projectMD/task-management.md'
    },
    {
      id: 5,
      title: 'Inventory stock management',
      details: 'Full sollution for company property and equipment management. Website + mobile app.',
      icon: 'delivery',
      filesource: '../../projectMD/inventory-management.md'
    },
    {
      id: 6,
      title: 'Internal IT department website',
      details: 'Website, that act as an interface for all kinds of tasks, performed by IT department.',
      icon: 'world',
      filesource: '../../projectMD/it-department-website.md'
    }
  ],
  blogs: [
    {
      id: 1,
      title: 'Authorize ASP.NET Core app by Azure AD groups using Graph API',
      featuredImage: '/images/blog-image-1.jpg',
      filesource: '../../blog/authorize-aspnet-core-apps.md',
      createDay: "28",
      createMonth: 'December',
      createYear: "2019"
    },
    {
      id: 2,
      title: 'DefaultAzureIdentity And Its Various Credential Types',
      featuredImage: '/images/blog-image-2.png',
      filesource: '../../blog/default-azure-identity.md',
      createDay: "30",
      createMonth: 'December',
      createYear: "2020"
    }
  ],
  contactInfo: {
    translations: {
      _getInTouch: 'Get In Touch',
      _contactMe: 'Contact Me',
      _enterYourName: 'Enter your name*',
      _enterYourEmail: 'Enter your email*',
      _enterYourSubject: 'Enter your subject*',
      _enterYourMessage: 'Enter your message*',
      _sendEmail: 'Send Mail',
      _phone: 'Phone',
      _email: 'Email',
      _address: 'Address',
      _thereWasAnErrorSendingMessage: 'There was an error sending message.',
      _messageWasSent: 'Message has been sent!',
      _nameIsRequired: 'Name is required',
      _emailIsRequired: 'Email is required',
      _subjectIsRequired: 'Subject is required',
      _messageIsRequired: 'Message is required'
    },    
    phoneNumbers: ['+421 903 441 145'],
    emailAddress: ['miroslav.adamec@hotmail.com'],
    address: "Bratislava, Slovakia"
  }
}

const databaseSk = {
  navigation: {
    _home: "ÚVOD",
    _about: "O MNE",
    _resume: "ŽIVOTOPIS",
    _portfolios: "PROJEKTY",
    _blogs: "BLOG",
    _contact: "KONTAKT"
  },
  information: {
    _aboutMe: "O MNE",
    _welcome: 'Vitajte, som',
    _iAm: 'Som',
    _fullname: "Meno",
    _age: "Vek",
    _phone: "Telefón",
    _nationality: "Národnosť",
    _languages: "Jazyky",
    _email: "Email",
    _freelance: "Freelance",
    _downloadCv: "SŤIAHNUŤ CV",
    name: 'Miroslav Adamec',
    aboutContent: "Som full-stack .NET programátor. Zameriavam sa na automatizáciu biznis procesov pomocou (nie len) claudových technológií.",
    aboutDetail: "Svoj prvý program som vyvinul pred 15 rokmi v jazyku Pascal. Počas šudia na vysokej škole som začal pracovať v medzinárodnej cestovnej agentúre. Videl som príležitosti ako automatizovať prácu zamestnancov a následne som sa začal venovať ich realizácii. Táto forma znižovania nákladov na beh spoločnosti sa stala mojim hlavným pracovným cieľom.",
    age: 31,
    phone: '+421 903 441 145',
    nationality: 'slovenská',
    language: 'anglický, slovenský',
    email: 'miroslav.adamec@hotmail.com',
    address: '',
    freelanceStatus: 'dostupný',
    socialLinks: {
      facebook: '',
      twitter: '',
      pinterest: '',
      behance: '',
      linkedin: 'https://www.linkedin.com/in/miroslav-adamec-672b13b7/',
      dribbble: '',
      github: 'https://github.com/mirecad'
    },
    brandImage: '/images/brand-image.jpg',
    aboutImage: '/images/about-image.jpg',
    aboutImageLg: '/images/about-image-lg.jpg',
    cvfile: '/files/Adamec_MiroslavEN.pdf'
  },
  services: [
    {
      title: "Automatizácia procesov",
      icon: 'investment',
      details: "Každý opakovaný proces môže byť zautomatizovaný. Nechajte svojich zamestnancov robiť ziskovú prácu."
    },
    {
      title: "Vývoj webových stránok",
      icon: 'world',
      details: "Spravujte svoj biznis pohodlne z internetového prehliadača kdekoľvek na zemi."
    },
    {
      title: "Mobilné aplikácie",
      icon: 'mobile',
      details: "Každý dnes vlastní smartfón. Vložte kontrolu vašich systémov do svojho mobilného zariadenia."
    }
  ],
  reviews: [
    {
      id: 1,
      content: "Miroslav je veľmí talentovaný a má silné technické schopnosti a skúsenosti. Je veľou výhodou mať ho v tíme.",
      author: {
        name: 'Vilém Svoboda',
        designation: 'CIO, Invia group (CZ)'
      }
    },
    {
      id: 2,
      content: "Miroslav s nami spolupracuje už skoro 2 roky. Ceníme si jeho samostatnosť a rýchlu reakčnú dobu.",
      author: {
        name: 'Peter Harbulák',
        designation: 'Zakladateľ, GYN-FIV, a.s. (CZ)'
      }
    },
    {
      id: 3,
      content: "Dôraz kladený na detaily robí Miroslavovu prácu odlišnú od všetkých ostatných primerných programátorov.",
      author: {
        name: 'Michal Hliničan',
        designation: 'Product Owner, George Labs (AT)'
      }
    }
  ],
  skills: [
    {
      title: "HTML5",
      value: 95
    },
    {
      title: "CSS3",
      value: 90
    },
    {
      title: "Javascript",
      value: 70
    },
    {
      title: "jQuery",
      value: 85
    },
    {
      title: "ReactJS",
      value: 80
    },
    {
      title: "Photoshop",
      value: 65
    }
  ],
  portfolios: [
    {
      id: 1,
      title: "T-shirt Mockup",
      subtitle: "A beautiful t-shirt mockup.",
      imageUrl: "/images/portfolio-image-1.jpg",
      largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
      url: 'https://dribbble.com'
    },
    {
      id: 2,
      title: "Coffee Mug",
      subtitle: "Awesome coffee mug design.",
      imageUrl: "/images/portfolio-image-4.jpg",
      largeImageUrl: [
        "/images/portfolio-image-4-lg.jpg",
        "/images/portfolio-image-4-lg2.jpg"
      ],
      url: 'https://facebook.com'
    },
    {
      id: 3,
      title: "Tea & Coffee Mug",
      subtitle: "Beautiful mug with logo.",
      imageUrl: "/images/portfolio-image-2.jpg",
      url: 'https://pinterest.com'
    },
    {
      id: 4,
      title: "Pen Holder",
      subtitle: "A pen holder with beautiful design.",
      imageUrl: "/images/portfolio-image-3.jpg",
      largeImageUrl: ["/images/portfolio-image-3-lg.jpg"],
      url: '#'
    },
    {
      id: 5,
      title: "Mug",
      subtitle: "Mug with awesome style",
      imageUrl: "/images/portfolio-image-5.jpg",
      largeImageUrl: ["/images/portfolio-image-5-lg.jpg"]
    },
    {
      id: 6,
      title: "Pendrive",
      subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/portfolio-image-6.jpg",
      largeImageUrl: ["/images/portfolio-image-6-lg.jpg"]
    },
    {
      id: 7,
      title: "Beautiful Pendrive",
      subtitle: "Pendrive with great design & flexible.",
      imageUrl: "/images/portfolio-image-7.jpg",
      largeImageUrl: ["/images/portfolio-image-7-lg.jpg"],
      url: 'https://twitter.com'
    },
    {
      id: 8,
      title: "Sticker",
      subtitle: "Clip sticker mockup design.",
      imageUrl: "/images/portfolio-image-8.jpg",
      largeImageUrl: ["/images/portfolio-image-8-lg.jpg"]
    },
    {
      id: 9,
      title: "Packet",
      subtitle: "Beautiful packet & product design.",
      imageUrl: "/images/portfolio-image-9.jpg",
      largeImageUrl: ["/images/portfolio-image-9-lg.jpg"]
    },
    {
      id: 10,
      title: "Pen Holder",
      subtitle: "A pen holder with beautiful design.",
      imageUrl: "/images/portfolio-image-3.jpg",
      largeImageUrl: ["/images/portfolio-image-3-lg.jpg"],
      url: '#'
    },
    {
      id: 11,
      title: "Coffee Mug",
      subtitle: "Awesome coffee mug design.",
      imageUrl: "/images/portfolio-image-4.jpg",
      largeImageUrl: [
        "/images/portfolio-image-4-lg.jpg",
        "/images/portfolio-image-4-lg2.jpg"
      ],
      url: 'https://facebook.com'
    },
    {
      id: 12,
      title: "Tea & Coffee Mug",
      subtitle: "Beautiful mug with logo.",
      imageUrl: "/images/portfolio-image-2.jpg",
      url: 'https://pinterest.com'
    },
    {
      id: 13,
      title: "T-shirt Mockup",
      subtitle: "A beautiful t-shirt mockup.",
      imageUrl: "/images/portfolio-image-1.jpg",
      largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
      url: 'https://dribbble.com'
    },
    {
      id: 14,
      title: "Mug",
      subtitle: "Mug with awesome style",
      imageUrl: "/images/portfolio-image-5.jpg",
      largeImageUrl: ["/images/portfolio-image-5-lg.jpg"]
    },
    {
      id: 15,
      title: "Pendrive",
      subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/portfolio-image-6.jpg",
      largeImageUrl: ["/images/portfolio-image-6-lg.jpg"]
    },
    {
      id: 16,
      title: "Beautiful Pendrive",
      subtitle: "Pendrive with great design & flexible.",
      imageUrl: "/images/portfolio-image-7.jpg",
      largeImageUrl: ["/images/portfolio-image-7-lg.jpg"],
      url: 'https://twitter.com'
    },
    {
      id: 17,
      title: "Sticker",
      subtitle: "Clip sticker mockup design.",
      imageUrl: "/images/portfolio-image-8.jpg",
      largeImageUrl: ["/images/portfolio-image-8-lg.jpg"]
    },
    {
      id: 18,
      title: "Packet",
      subtitle: "Beautiful packet & product design.",
      imageUrl: "/images/portfolio-image-9.jpg",
      largeImageUrl: ["/images/portfolio-image-9-lg.jpg"]
    },
    {
      id: 19,
      title: "T-shirt Mockup",
      subtitle: "A beautiful t-shirt mockup.",
      imageUrl: "/images/portfolio-image-1.jpg",
      largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
      url: 'https://dribbble.com'
    },
    {
      id: 20,
      title: "Coffee Mug",
      subtitle: "Awesome coffee mug design.",
      imageUrl: "/images/portfolio-image-4.jpg",
      largeImageUrl: [
        "/images/portfolio-image-4-lg.jpg",
        "/images/portfolio-image-4-lg2.jpg"
      ],
      url: 'https://facebook.com'
    },
    {
      id: 21,
      title: "Tea & Coffee Mug",
      subtitle: "Beautiful mug with logo.",
      imageUrl: "/images/portfolio-image-2.jpg",
      url: 'https://pinterest.com'
    },
    {
      id: 22,
      title: "Pen Holder",
      subtitle: "A pen holder with beautiful design.",
      imageUrl: "/images/portfolio-image-3.jpg",
      largeImageUrl: ["/images/portfolio-image-3-lg.jpg"],
      url: '#'
    },
    {
      id: 23,
      title: "Mug",
      subtitle: "Mug with awesome style",
      imageUrl: "/images/portfolio-image-5.jpg",
      largeImageUrl: ["/images/portfolio-image-5-lg.jpg"]
    }
  ],
  experience: {
    _workingExperience: "Pracovné skúsenosti",
    workingExperience: [
      {
        id: 1,
        year: "JANUÁR 2019 - Súčasnosť",
        position: "FREELANCER",
        company: "",
        details: "Začal som budovať viaceré druhy aplikácií pre rôznych klientov. Začínajúc automatickým generovaním excelovských súborov, cez rozšírenia existujúcich PHP projektov, až po budovanie väčších webových stránok od nuly."
      },
      {
        id: 2,
        year: "MAREC 2018 - Súčasnosť",
        position: ".NET DEVELOPMENT",
        company: "Invia Bratislava",
        details: "Vzdal som sa pozície lídra podpory a začal technicky viesť malú vývojovú jednotku (ja + 1 programátor + 1 prevádzkový technik). Moje primárne zameranie je automatizácia business procesov, udržiavanie doteraz vytvorených projektov a ich postupné pretváranie na fungovanie v cloude. Do roku 2021 sme premigrovali takmer všetky aplikácie na .NET Core a Azure. Toto zvýšilo stabilitu a zredukovalo čas potrebný na prevádzku služieb. Najpoužívanejšie technológie: Azure, ASP.NET Core, MS SQL, Docker"
      },
      {
        id: 3,
        year: "JÚN 2015 - MAREC 2018",
        position: "IT SUPPORT LEADER + .NET DEVELOPMENT",
        company: "Invia Bratislava",
        details: "Vedenie tímu IT podpory (10osôb) + návrh, vývoj, údržba rôznych .NET služieb a aplikácií, administrácia Windows infraštruktúry a VoIP ústredne. Môj primárny cieľ je redukovať ľudské zásahy v úlohách riešených IT oddelením a zvyšovať kvalitu a spoľahlivosť spravovaných služieb. Dosahujem toho analýzou aktuálnych procesov a potom návrhom a implementáciou rôznych služieb a aplikácií postavených prevažne na platforme .NET. Projekty týkajúce sa programovania riešim od návrhu po údržbu sám (veľkosť spoločnosti: 350 –700 zamestnancov podľa sezóny)."
      },
      {
        id: 4,
        year: "JǓN 2014 - JÚN 1016",
        position: "DOMAIN ADMINISTRATOR + .NET DEVELOPMENT",
        company: "Invia Brno",
        details: "Administrácia Windows prostredia a VoIP ústredne v medzinárodnej cestovnej agentúre. Návrh,vývoja údržbasystému, ktorý automatizuje správu účtov a oprávnení. Priamo prepája interný CRM systém  s Active Directory, Exchange a VoIP ústredňou. Týmto eliminuje manuálne úkony IT administrátorov s tým spojené."
      },
      {
        id: 5,
        year: "JǓN 2012 - JǓN 1014",
        position: "DOMAIN ADMINISTRATOR",
        company: "Invia Brno",
        details: "Administrácia Windows prostredia v medzinárodnej cestovnej agentúre. Active directory, Exchange, Hyper-V, MS SQL, GPO, IIS, DHCP + Linux VoIP ústredňa. Firma má 200 – 500 zamestnancov podľa sezóny."
      }
    ],
    educationExperience: [
      {
        id: 1,
        year: "JǓN 2017",
        graduation: "INŽINIER",
        university: "SLOVENSKÁ TECHNICKÁ UNIVERZITA, BRATISLAVA",
        details: "Fakulta elektrotechniky a informatiky, obor aplikovaná  informatika"
      },
      {
        id: 2,
        year: "JANUÁR 2015",
        graduation: "BAKALÁR",
        university: "MASARYKOVA UNIVERZITA, BRNO",
        details: "Fakulta informatiky, Počítačové systémy a spracovanie dát"
      }
    ]
  },
  _projectsTitle: "NAJVÄČŠIE PROJEKTY",
  projects: [
    {
      id: 1,
      title: 'Archivácia emailov v reálnom čase',
      details: 'Doplnok pre Microsoft Exchange, ktorý v reálnom čase prehľadáva emaily a archivuje ich.',
      icon: 'envelope',
      filesource: '../../projectMD/mxarchive.md'
    },
    {
      id: 2,
      title: 'Synchronizácia účtov zamestnancov',
      details: 'Služba, ktorá spája CRM systém a IT systémy (emailový server, AD, tel. ústredňa).',
      icon: 'user',
      filesource: '../../projectMD/employee-synchronization.md'
    },
    {
      id: 3,
      title: 'Automatizácia biznis procesov',
      details: 'Projekty, ktoré automatizujú manuálne úlohy vykonávané ľudmi na počítačoch.',
      icon: 'cog',
      filesource: '../../projectMD/process-automation.md'
    },
    {
      id: 4,
      title: 'Web na správu úloh lekárskej kliniky',
      details: 'Stránka zabezpečujúca komunikáciu a spravú úloh doktorov a zamestnancov kliniky.',
      icon: 'flag',
      filesource: '../../projectMD/task-management.md'
    },
    {
      id: 5,
      title: 'Inventár pre správu majetku',
      details: 'Plné riešenie pre inventárnu správu majetku spoločnosti. Web + mobilná aplikácia.',
      icon: 'delivery',
      filesource: '../../projectMD/inventory-management.md'
    },
    {
      id: 6,
      title: 'Interná stránka pre IT oddelenie',
      details: 'Stránka, slúžiaca ako rozhranie na vykonávanie rôznych úloh vykonávaných IT oddelením.',
      icon: 'world',
      filesource: '../../projectMD/it-department-website.md'
    }
  ],
  blogs: [
    {
      id: 1,
      title: 'Authorize ASP.NET Core app by Azure AD groups using Graph API',
      featuredImage: '/images/blog-image-1.jpg',
      filesource: '../../blog/authorize-aspnet-core-apps.md',
      createDay: "28",
      createMonth: 'December',
      createYear: "2019"
    },
    {
      id: 2,
      title: 'DefaultAzureIdentity And Its Various Credential Types',
      featuredImage: '/images/blog-image-2.png',
      filesource: '../../blog/default-azure-identity.md',
      createDay: "30",
      createMonth: 'December',
      createYear: "2020"
    }
  ],
  contactInfo: {
    translations: {
      _getInTouch: 'Pošli správu',
      _contactMe: 'Kontakt',
      _enterYourName: 'Zadaj svoje meno*',
      _enterYourEmail: 'Zadaj svoj email*',
      _enterYourSubject: 'Zadaj predmet*',
      _enterYourMessage: 'Zadaj správu*',
      _sendEmail: 'Pošli Email',
      _phone: 'Telefón',
      _email: 'Email',
      _address: 'Adresa',
      _thereWasAnErrorSendingMessage: 'Nastal problém pri posielaní správy.',
      _messageWasSent: 'Správa bola odoslaná!',
      _nameIsRequired: 'Meno je povinné',
      _emailIsRequired: 'Email je povinný',
      _subjectIsRequired: 'Predmet je povinný',
      _messageIsRequired: 'Správa je povinná'
    },    
    phoneNumbers: ['+421 903 441 145'],
    emailAddress: ['miroslav.adamec@hotmail.com'],
    address: "Bratislava, Slovensko"
  }
}

Mock.onGet("/api/navigation").reply(config => {
  const response = database.navigation;
  return [200, response];
});
Mock.onGet("/api/navigation?lang=sk").reply(config => {
  const response = databaseSk.navigation;
  return [200, response];
});

Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});
Mock.onGet("/api/information?lang=sk").reply(config => {
  const response = databaseSk.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});
Mock.onGet("/api/services?lang=sk").reply(config => {
  const response = databaseSk.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});
Mock.onGet("/api/reviews?lang=sk").reply(config => {
  const response = databaseSk.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});
Mock.onGet("/api/skills?lang=sk").reply(config => {
  const response = databaseSk.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});
Mock.onGet("/api/portfolios?lang=sk").reply(config => {
  const response = databaseSk.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});
Mock.onGet("/api/experience?lang=sk").reply(config => {
  const response = databaseSk.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply(config => {
  const response = database.blogs;
  return [200, response];
});
Mock.onGet("/api/blog?lang=sk").reply(config => {
  const response = databaseSk.blogs;
  return [200, response];
});

Mock.onGet("/api/projects").reply(config => {
  const response = database.projects;
  return [200, response];
});
Mock.onGet("/api/projects?lang=sk").reply(config => {
  const response = databaseSk.projects;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});
Mock.onGet("/api/contactinfo?lang=sk").reply(config => {
  const response = databaseSk.contactInfo;
  return [200, response];
});