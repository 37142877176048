import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Sectiontitle from "../components/Sectiontitle";
import Layout from "../components/Layout";
import Pagination from "../components/Pagination";
import ProjectsView from "../components/ProjectsView";
import AppContext from '../components/AppContext';

function Projects() {
    const [posts, setPosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(9);
    const appContextRoot = useContext(AppContext);
    const isSlovakLang = appContextRoot.appContext.displaySlovak;

    useEffect(() => {
        let mounted = true;
        axios.get(isSlovakLang ? '/api/projects?lang=sk' : '/api/projects').then(response => {
          if(mounted){
            setPosts(response.data);
          }
        });
        return () => mounted = false;
      }, [posts, isSlovakLang]);

      const indexOfLastPost = currentPage * postsPerPage;
      const indexOfFirstPost = indexOfLastPost - postsPerPage;
      const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);
    
      const paginate = (e, pageNumber) => {
        e.preventDefault();
        setCurrentPage(pageNumber);
      }

    return (
        <Layout>
            <div className="mi-about mi-section mi-padding-top mi-padding-bottom">
                <div className="container">
                    <Sectiontitle title={(() => {if (isSlovakLang) {return ("NAJVÄČŠIE PROJEKTY")} else {return ("LARGEST PROJECT")}})() }/>
                    {<ProjectsView projects={currentPosts} />}
                    {!(posts.length > postsPerPage) ? null : (
                        <Pagination
                            className="mt-50"
                            itemsPerPage={postsPerPage}
                            totalItems={posts.length}
                            paginate={paginate}
                            currentPage={currentPage}
                        />
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default Projects;