import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import ReactMarkdown from "react-markdown/with-html";
import Layout from "../components/Layout";
import CodeBlock from '../components/CodeBlock'
import AppContext from '../components/AppContext';

function ProjectDetails(props) {
  const [content, setContent] = useState("");
  const projecFile = props.match.params.title;
  const appContextRoot = useContext(AppContext);
  const isSlovakLang = appContextRoot.appContext.displaySlovak;

  useEffect(() => {
    axios
      .get(require(`../projectMD/${projecFile}${isSlovakLang ? `_SK` : ``}.md`))
      .then(result => {
        setContent(result.data);
      })
  }, [content, projecFile, isSlovakLang]);

  return (
    <Layout>
      <div className="mi-blog-details mi-section mi-padding-top mi-padding-bottom">
        <div className="container">
          <ReactMarkdown source={content} escapeHtml={false} renderers={{ code: CodeBlock }}></ReactMarkdown>
        </div>
      </div>
    </Layout>
  );
}

export default ProjectDetails;
