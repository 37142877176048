import React, { useState } from "react";
import * as Icon from 'react-feather';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.scss";
import About from "./pages/About";
import BlogDetails from "./pages/BlogDetails";
import Blogs from "./pages/Blogs";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Notfound from "./pages/Notfound";
// import Portfolios from "./pages/Portfolios";
import Projects from "./pages/Projects";
import ProjectDetails from "./pages/ProjectDetails";
import Resumes from "./pages/Resumes";
import AppContext from "./components/AppContext"

function App() {
  const [lightMode, setLightMode] = useState(false); // Made it true if you want to load your site light mode primary
  const [appContext, setAppContext] = useState({menuShown: false, displaySlovak: false});

  lightMode ? document.body.classList.add('light') : document.body.classList.remove('light');

  const handleMode = () => {
    if (!lightMode) {
      setLightMode(true);
      document.body.classList.add('light')
    } else {
      setLightMode(false);
      document.body.classList.remove('light')
    }
  }

  const handleLocalization = () => {
    setAppContext({...appContext, displaySlovak: !appContext.displaySlovak})
  }

  return (
    <AppContext.Provider value={{appContext, setAppContext}}>
      <BrowserRouter>
        <div className={appContext.menuShown ? 'light-mode' : 'light-mode d-none d-sm-flex'}>
          <span className="icon">
            <Icon.Sun />
          </span>
          <button className={lightMode ? 'light-mode-switch active' : 'light-mode-switch'} onClick={() => handleMode()}></button>
        </div>
        <div className={appContext.menuShown ? 'navigation-mode language-switch' : 'navigation-mode language-switch d-none d-sm-flex'} onClick={() => handleLocalization()}>
          {/* <img class="img-cur" src={appContext.displaySlovak ? '/images/en.svg' : '/images/SK_bw1.png'} alt={appContext.displaySlovak ? 'english' : 'slovak'} height="30" width="40" onClick={() => handleLocalization()}/> */}
          {appContext.displaySlovak ? 'EN' : 'SK'}
        </div>
        <Switch>
          <Route path="/" exact>
            <Home lightMode={lightMode} />
          </Route>
          <Route path="/about" component={About} />
          <Route path="/resume" component={Resumes} />
          {/* <Route path="/portfolios" component={Portfolios} /> */}
          <Route path="/projects" exact component={Projects} />
          <Route path="/projects/project-details/:id/:title" component={ProjectDetails} />
          <Route path="/blogs" exact component={Blogs} />
          <Route path="/blogs/blog-details/:id/:title" component={BlogDetails} />
          <Route path="/contact" component={Contact} />
          <Route path="*" component={Notfound} />
        </Switch>
      </BrowserRouter>
    </AppContext.Provider>
  );
}

export default App;
