import React from "react";
import LineIcon from "react-lineicons";
import { Link } from "react-router-dom";

function Project(props) {
  const { id, title, filesource, icon, details } = props.data;
  const getNospaceTitle = filesource => {
    let tempArr = filesource.split('/');
    let fileName = tempArr[tempArr.length - 1];
    let getName = fileName.slice(0, -3);
    return getName;
  };

  return (
    <Link to={`projects/project-details/${id}/${getNospaceTitle(filesource)}`}>
      <div className="mi-service">
        <span className="mi-service-icon">
          <LineIcon name={icon} />
        </span>
        <h5>{title}</h5>
        <p>{details}</p>
      </div>
    </Link>
  );
}

export default Project;
