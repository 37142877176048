import axios from 'axios';
import React, { useEffect, useState, useContext } from 'react';
import LineIcon from 'react-lineicons';
import { Link, NavLink } from 'react-router-dom';
import AppContext from './AppContext';

function Header() {
    const [information, setInformation] = useState("");
    const [navigation, setNavigation] = useState("");
    const [navigationToggler, setNavigationToggler] = useState(false);
    const appContextRoot = useContext(AppContext);
    const isSlovakLang = appContextRoot.appContext.displaySlovak;

    const handleNavigationToggler = () => {
        setNavigationToggler(!navigationToggler);
        appContextRoot.setAppContext(appContext => {
            return {...appContext, menuShown : !navigationToggler}
        });
    }

    const handleNavigationClick = () => {
        appContextRoot.setAppContext(appContext => {
            return {...appContext, menuShown : false}
        });
    }

    useEffect(() => {
        axios.get(isSlovakLang ? '/api/information?lang=sk' : '/api/information')
            .then(response => {
                setInformation(response.data);
            })
    }, [isSlovakLang])
    useEffect(() => {
        axios.get(isSlovakLang ? '/api/navigation?lang=sk' : '/api/navigation')
            .then(response => {
                setNavigation(response.data);
            })
    }, [isSlovakLang])

    return (
        <nav className={navigationToggler ? "mi-header is-visible" : "mi-header"}>
            <button onClick={handleNavigationToggler} className="mi-header-toggler">
                {!navigationToggler ? <LineIcon name="menu" /> : <LineIcon name="close" />}
            </button>
            <div className="mi-header-inner">
                <div className="mi-header-image">
                    <Link to="/" onClick={handleNavigationClick}>
                        <img src={information.brandImage} alt="brandimage" />
                    </Link>
                </div>

                <ul className="mi-header-menu">
                    <li><NavLink exact to="/" onClick={handleNavigationClick}><span>{navigation._home}</span></NavLink></li>
                    <li><NavLink to="/about" onClick={handleNavigationClick}><span>{navigation._about}</span></NavLink></li>
                    <li><NavLink to="/resume" onClick={handleNavigationClick}><span>{navigation._resume}</span></NavLink></li>
                    {/* <li><NavLink to="/portfolios" onClick={handleNavigationClick}><span>Portfolios</span></NavLink></li> */}
                    <li><NavLink to="/projects" onClick={handleNavigationClick}><span>{navigation._portfolios}</span></NavLink></li>
                    <li><NavLink to="/blogs" onClick={handleNavigationClick}><span>{navigation._blogs}</span></NavLink></li>
                    <li><NavLink to="/contact" onClick={handleNavigationClick}><span>{navigation._contact}</span></NavLink></li>
                </ul>
                 <p className="mi-header-copyright">&nbsp; {/*&copy; {new Date().getFullYear()} <b><a rel="noopener noreferrer" target="_blank" href="https://nuclearthemes.com">NuclearThemes</a></b>*/}</p> 
            </div>
        </nav>
    )
}


export default Header;