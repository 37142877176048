import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import Slider from "react-slick";
import Layout from "../components/Layout";
import Sectiontitle from "../components/Sectiontitle";
import Testimonial from "../components/Testimonial";
import AppContext from '../components/AppContext';


function About() {
  // const [toggler, setToggler] = useState(false);
  const [information, setInformation] = useState("");
  const [reviews, setReviews] = useState([]);
  const appContextRoot = useContext(AppContext);
  const isSlovakLang = appContextRoot.appContext.displaySlovak;

  const sliderSettings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover: true,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // const handleToggler = (event) => {
  //   setToggler({
  //     toggler: !toggler,
  //   });
  // };

  useEffect(() => {
    axios.get(isSlovakLang ? '/api/information?lang=sk' : '/api/information').then((response) => {
      setInformation(response.data);
    });
    axios.get(isSlovakLang ? '/api/reviews?lang=sk' : '/api/reviews').then((response) => {
      setReviews(response.data);
    });
  }, [isSlovakLang]);

  return (
    <Layout>
      <div className="mi-about-area mi-section mi-padding-top">
        <div className="container">
          <Sectiontitle title={information._aboutMe} />
          <div className="row">
            {/* <div className="col-lg-6">
              <div className="mi-about-image">
                <ProgressiveImage
                  src={information.aboutImage}
                  placeholder="/images/about-image-placeholder.png"
                >
                  {src => <img src={src} alt="aboutimage" onClick={() => handleToggler(!toggler)} />}
                </ProgressiveImage>
                <span className="mi-about-image-icon">
                  <Icon.ZoomIn />
                </span>
                <FsLightbox
                  toggler={toggler}
                  sources={[information.aboutImageLg]}
                />
              </div>
            </div> */}
            <div className="col-lg-6">
              <h3>
                  {information._iAm} <span className="color-theme">{information.name}</span>
              </h3>
              <p>
                  {information.aboutDetail}
              </p>
            </div>
            <div className="col-lg-6">
              <div className="mi-about-content">
                {/* <h3>
                  I am <span className="color-theme">{information.name}</span>
                </h3>
                <p>
                  {information.aboutContent}
                </p> */}
                <ul>
                  {!information.name ? null : (
                    <li>
                      <b>{information._fullname}</b> {information.name}
                    </li>
                  )}
                  {!information.age ? null : (
                    <li>
                      <b>{information._age}</b> {information.age} Years
                    </li>
                  )}
                  {!information.phone ? null : (
                    <li>
                      <b>{information._phone}</b> {information.phone}
                    </li>
                  )}
                  {!information.nationality ? null : (
                    <li>
                      <b>{information._nationality}</b> {information.nationality}
                    </li>
                  )}
                  {!information.language ? null : (
                    <li>
                      <b>{information._languages}</b> {information.language}
                    </li>
                  )}
                  {!information.email ? null : (
                    <li>
                      <b>{information._email}</b> {information.email}
                    </li>
                  )}
                  {!information.address ? null : (
                    <li>
                      <b>{information._address}</b> {information.address}
                    </li>
                  )}
                  {!information.freelanceStatus ? null : (
                    <li>
                      <b>{information._freelance}</b> {information.freelanceStatus}
                    </li>
                  )}
                </ul>
                <a href={information.cvfile} className="mi-button">
                  {information._downloadCv}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mi-review-area mi-section mi-padding-top mi-padding-bottom">
        <div className="container">
          <Sectiontitle title="Reviews" />
          <div className="row justify-content-center">
            <div className="col-12">
              <Slider className="mi-testimonial-slider" {...sliderSettings}>
                {reviews.map((review) => (
                  <Testimonial key={review.id} content={review} />
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default About;
